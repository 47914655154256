import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill";
import {css} from '@emotion/react';


const BeratungImages = (props) => {
  const data = useStaticQuery(graphql`
    query {
      beratung: file(relativePath: { eq: "header/header_set_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, grayscale: false, quality: 75) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_01: file(relativePath: { eq: "grafiken/beratung_01_shadow.png" }) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50, grayscale: false) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_02: file(relativePath: { eq: "grafiken/beratung_02.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400, grayscale: false) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_03: file(relativePath: { eq: "grafiken/beratung_03.png" }) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50, grayscale: false) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_04: file(relativePath: { eq: "grafiken/01_Startseite_04_Keine-Uebersicht_gesch.png" }) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50, grayscale: false) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_05: file(relativePath: { eq: "grafiken/beratung_05.png" }) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50, grayscale: false) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_06: file(relativePath: {eq: "grafiken/startseite_01_shadow.png"}) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_07: file(relativePath: {eq: "grafiken/zahnrad_sh.png"}) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_08: file(relativePath: {eq: "grafiken/beratung_08_shadow.png"}) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 50) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      },
      beratung_09: file(relativePath: {eq: "grafiken/Strategie.png"}) {
        childImageSharp {
          fluid(maxWidth: 512, quality: 60) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    } 
  `)
  
  if (props.image === "beratung")  return  <Img alt="Proceed Beratung Header" fluid={data.beratung.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" css={css`width: 100%; height: 100%;`} />
  else if (props.image === "beratung_01") return <Img alt="Proceed Beratung Agilität " fluid={data.beratung_01.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "beratung_02") return <Img alt="Proceed Beratung Produktion" fluid={data.beratung_02.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "beratung_03") return <Img alt="Proceed Beratung " fluid={data.beratung_03.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "beratung_04") return <Img alt="Proceed Beratung " fluid={data.beratung_04.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "beratung_05") return <Img alt="Proceed Beratung Führung" fluid={data.beratung_05.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "beratung_06") return <Img alt="Proceed Beratung Werte" fluid={data.beratung_06.childImageSharp.fluid} objectFit="contain" css={css`height: 100%; width: 100%;`} />
  else if (props.image === "beratung_07") return <Img alt="Proceed Beratung " fluid={data.beratung_07.childImageSharp.fluid} objectFit="contain" css={css`height: auto; width: 100%;`} />
  else if (props.image === "beratung_08") return <Img alt="Proceed Beratung " fluid={data.beratung_08.childImageSharp.fluid} objectFit="contain" css={css`height: auto; width: 100%;`} />
  else if (props.image === "beratung_09") return <Img alt="Proceed Beratung " fluid={data.beratung_09.childImageSharp.fluid} objectFit="contain" css={css`height: auto; width: 100%;`} />
  
  else return null
}

export default BeratungImages
