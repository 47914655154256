import React from "react";
import {Wrapper, FlexContainer, FlexBox, FlexContainerRow, FlexBoxRow, FlexBoxColumn, FlexContainerColumn, Container, background1, background2, orange, mq} from "../styles/globalstyles";
import Image from "../components/image";
import SEO from "../components/seo";
import { MediumOverline, SmallOverline } from "../components/overline";
import BeratungImages from "../components/images-beratung";
import { BeratungsCards } from "../components/slidein";
import { EventButton } from "../components/events/event-sorter";
import { ScrollIndicator } from "../components/scrollindicator";
import { ButtonMedium } from "../styles/buttons";
import Whitebook from "../components/site-components/whitebook";
import {css} from '@emotion/react';
import { useQuery, gql } from "@apollo/client";

const BERATUNGSSEITE = gql`
query beratung {
  beratungs {
    id
    stage
    beratungHeader {
      html
    }
    beratung1 {
      html
    }
    beratung2 {
      html
    }
    beratung3 {
      html
    }
    beratung4 {
      html
    }
  }
}
`

const Beratung = (props) => {
  const {loading, error, data} = useQuery(BERATUNGSSEITE) 
    return (
      <>
        <Wrapper background={background1}>
          <Container> 
            <Whitebook />
            <SEO 
            title="Beratung - Human, rentabel, anpassungsfähig" 
            pictureUrl={props.pageContext.contextData.metaImages[0].beratung.url} 
            description="Proceed passt zu Ihnen, wenn unsere Zusammenarbeit einer gemeinsamen Vision folgt: Ihr Unternehmen als schlagkräftige und anpassungsfähige Organisation." 
            />
            <EventButton />
            <FlexContainer direction="row" justify="space-between" align="center" css={css` min-height: 88vh; overflow: visible; padding-bottom: 1em;`}>
              <FlexBox direction="column" css={css`width: 100%; overflow: visible; padding: 1em; @media (min-width: 768px) {width: 55%}`}> 
                {data && <div dangerouslySetInnerHTML={{__html:  data.beratungs[0].beratungHeader.html}} /> }
                <ScrollIndicator css={{marginTop: "4em"}} />
              </FlexBox>
                <FlexBox css={css`display: none; width: 50%; margin-left: 50%;  height: auto; overflow: visible; position: absolute; @media (min-width: 768px) {display: flex}`}>
                  <div css={css`height: 100%; width: 100%; overflow: visible;  opacity: 1; `}>
                    <Image image="beratung" >
                    </Image>
                  </div>
                </FlexBox>
            </FlexContainer>
          </Container>
        </Wrapper>
        <Wrapper>
          <Container>
          <FlexContainerRow  > 
            <FlexBox justify="center" align="center" css={mq({order: [1], maxWidth: ["18em", "18em", "24em",  "30em"], marginBottom: ["2.4rem", "2.4rem", 0]})}>
                <BeratungImages image="beratung_01" css={css`overflow: visible; `} />
            </FlexBox>
            <FlexBox direction="column" align="flex-start" css={{order: 2}}> 
            <SmallOverline>DENKEN UND HANDELN IN NETZWERKEN</SmallOverline>
            {data && <div dangerouslySetInnerHTML={{__html:  data.beratungs[0].beratung1.html}} /> }
            </FlexBox>
          </FlexContainerRow>

          </Container>
        </Wrapper>
          
        <Wrapper background={background1}>
          <Container>
            <FlexContainerRow css={{['a']: {color: orange, textDecoration: 'underline'}}} >
              <FlexBox direction="column" css={{order: 2}}> 
              <SmallOverline>KOOPERATION SCHLÄGT KONKURRENZ</SmallOverline>
              {data && <div dangerouslySetInnerHTML={{__html:  data.beratungs[0].beratung2.html}} /> }
              </FlexBox>
              <FlexBox justify="center" align="center" css={mq({order: [1, 1, 3, 3], maxWidth: ["18em", "18em", "24em",  "30em"], marginBottom: ["2.4rem", "2.4rem", 0]})}>
                  <BeratungImages image="beratung_09" css={css`overflow: visible;`} />
              </FlexBox>
            </FlexContainerRow>
          </Container>
        </Wrapper>

        <Wrapper background={background2}>
          <Container>
            <BeratungsCards />
          </Container>
        </Wrapper>
        
        <Wrapper background={background1}>
          <Container>
            <FlexContainerColumn  > 
                <div css={mq({width: ["100%", "100%", "100%"]})}>
                  <FlexBoxColumn>
                    <SmallOverline>Mehr erfahren</SmallOverline>
                      <h2 css={mq({textAlign: ["center", "center", "left"]})}>Wir können Ihnen hier viel erzählen!</h2>
                      <p css={mq({textAlign: ["center", "center", "left"]})}>
                      Schließlich ist das unsere Website. Doch auch wir wissen: Der Beweis für das Können ist das Tun. Was wir für Sie und mit Ihnen gemeinsam tun können, finden wir am schnellsten im Gespräch heraus.
                      </p>

                  </FlexBoxColumn>
                  
                </div>
                <FlexBoxRow css={{width: "100%", marginTop: "3em", alignItems: "flex-start !important" }}> 
                  <FlexBoxColumn >
                    <h3  css={css`color: inherit;`}>Schreiben Sie uns</h3>
                    <p css={mq({textAlign: ["center", "center", "left"]})}>Haben Sie Fragen, Wünsche oder Anregungen? Bitte nehmen Sie Kontakt mit uns auf, wir helfen Ihnen gerne weiter!
                      </p>
                      <ButtonMedium color={orange} text="Zur Kontaktseite" link="/kontakt/" />
                </FlexBoxColumn>
                <FlexBoxColumn css={css`justify-content: flex-start !important;`} >
                    <h3 >
                      Oder lernen Sie uns kennen
                    </h3>
                    <p css={mq({textAlign: ["center", "center", "left"]})}> Treffen Sie uns einfach persönlich auf einer von vielen Veranstaltungen unserer Akademie.
                    </p>
                    
                    <ButtonMedium color={orange} text="Zur Akademie" link="/akademie/" />
                  </FlexBoxColumn>
              </FlexBoxRow>
            </FlexContainerColumn>
          </Container>
        </Wrapper>
        </>
    )
  }
  
export default Beratung
