import React, { useState, useEffect } from 'react';
import {motion} from 'framer-motion';
import { black, darkgrey, dark } from '../styles/globalstyles';

export const ScrollIndicator = () => {
    const [show, setShow] = useState(true)
    useEffect(() => {
        window.addEventListener("scroll", handleScroll, false);
        return () => window.removeEventListener("scroll", handleScroll, false);
        },[]);
    const handleScroll = (e) => (window.scrollY > 100 ? setShow(false) : setShow(true))
    const scrollcontainer = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "fixed",
        top: "90%",
        left: "11%",
        zIndex: 5
    }
    const scrollindicator = {
        width: "18px",
        height: "32px",
        border: "2px solid " +black,
        borderRadius: "8px",
        marginRight: "18px"
    }
    const scrollindicatorIcon ={
        width: "6px",
        height: "10px",
        position: "relative",
        background: dark,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "4px",
        borderRadius: "8px",
    } 

    const scrollindicatorVariants={
        fade: {
            y: 4,
            opacity: 0,
            transition: {loop: Infinity, duration: 1.5}
        }
    }
    return (
        <motion.div animate={show ? {opacity: 1, y: 0} : {opacity: 0, y: -20}} css={scrollcontainer}>
            <div css={scrollindicator}>
                <motion.div variants={scrollindicatorVariants} animate="fade" css={scrollindicatorIcon}></motion.div>
            </div>
            <h6 css={{color: darkgrey}}>weiter lesen</h6>
        </motion.div>
        
    )
}