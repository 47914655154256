import React, { useState } from "react";
import {motion} from "framer-motion";
import { FlexContainer, FlexBoxRow, beratungscard } from "../styles/globalstyles";
import { gql, useQuery } from "@apollo/client";
import { SmallOverline } from "./overline";
import {css} from '@emotion/react';

const BERATUNGCARD = gql`
{
    beratungs{
        beratungsschwerpunkte{
            html
        }
        beratungAngebote {
            textBlock {
                html
            }
            icon {
                id
                url
            }
            slideIn {
                html
            }
        }
    }
}`

export const BeratungsCards = () => {
    const [collapse, setCollapse] = useState(false)
    const [sliderContentState, setSliderContentState] = useState("")
    const {loading, error, data} = useQuery(BERATUNGCARD) 
    const sliderHandler =(e) => {
        setSliderContentState(e)
        collapse ? setTimeout(() => { 
        const body = document.body;
        const html = document.documentElement
        const scrollY = body.style.top;
        body.style.overflowY = 'visible';
        body.style.paddingRight = '0px';
        html.style.overflowY = 'visible';
            setCollapse(!collapse);
                
        }, 600) : document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        const html = document.documentElement
        body.style.overflowY = 'hidden';
        body.style.paddingRight = '10px';
        html.style.overflowY = 'hidden';
    setCollapse(!collapse)
    }
    if (loading) {return <p>loading</p>}
    if (error) {return <p>error</p>}
    if (data){
    return(
        <>
        <FlexContainer direction="column" align="left" >
            <div>
                <SmallOverline>Umfassendes Wissen aus einer Hand</SmallOverline>
                {data && <div dangerouslySetInnerHTML={{__html: data.beratungs[0].beratungsschwerpunkte.html}} />}
            </div>
            <FlexBoxRow css={css`flex-wrap: wrap; overflow: visible; width: 100%; margin-top: 4em;`}>
                {data && data.beratungs[0].beratungAngebote.map(beratungAngebot => 
                    <motion.div className="shadow" css={beratungscard}>
                        <div css={css`width: 40%; height: 10em; padding: 1em;`}> 
                            <img src={beratungAngebot.icon.url} image="beratung_01"  />
                        </div>
                            <div css={css`width: 60%; margin-block-end: 0 !important;`}>
                            <div dangerouslySetInnerHTML={{__html: beratungAngebot.textBlock.html}} />
                        </div>
                    </motion.div>
                ) }
            </FlexBoxRow>
          </FlexContainer>
          {/* <AnimatePresence exitBeforeEnter> 
            {collapse &&
            <SlideContainer css={css`z-index: 14;`}> 
            <motion.div variants={navbackground} initial="initial" animate="animate" exit="exit" 
            css={css`width: 100vw; height: 100vh; overflow: hidden; z-index: 13; position: absolute;`}>
                <motion.div key="345" css={slidein}  
                initial="initial"
                animate="visible"
                exit="hidden"
                variants={menuenterright}
                onClick={()=> {return null}}
                >
                    <div css={closer} onClick={() => sliderHandler()}>
                        <IoMdClose /> Schließen
                    </div>
                { sliderContentState === "agilitaet" && 
                <div css={beratungsModal}>
                    
                    {data && <div dangerouslySetInnerHTML={{__html: data.beratungs[0].beratungBlock1Slide.html}} />}
                    <h4>Mehr erfahren auf der Proceed-Akademie</h4>
                    <motion.div whileHover={{scale: 1.02}} onClick={() => sliderHandler()} css={css`max-width: 36em;`}><Link to="/akademie"><ButtonAkademie /></Link></motion.div >
                    <h4>Oder schreiben Sie uns direkt</h4>
                    <motion.div whileHover={{scale: 1.02}} onClick={() => sliderHandler()} css={css`max-width: 36em;`}><Link to="/kontakt"><ButtonKontakt  /></Link></motion.div >
                </div>
                
               }
                { sliderContentState == "fuehrung" && 
                <div css={beratungsModal}>
                    {data && <div dangerouslySetInnerHTML={{__html: data.beratungs[0].beratungBlock2Slide.html}} />}
                    <h4>Mehr erfahren auf der Proceed-Akademie</h4>
                    <motion.div whileHover={{scale: 1.02}} onClick={() => sliderHandler()} css={css`max-width: 36em;`}><Link to="/akademie"><ButtonAkademie /></Link></motion.div >
                    <h4>Oder schreiben Sie uns direkt</h4>
                    <motion.div whileHover={{scale: 1.02}} onClick={() => sliderHandler()} css={css`max-width: 36em;`}><Link to="/kontakt"><ButtonKontakt  /></Link></motion.div >
                </div>
                }
                { sliderContentState == "werte" && 
                <div css={beratungsModal}>
                    {data && <div dangerouslySetInnerHTML={{__html: data.beratungs[0].beratungBlock3Slide.html}} />}
                    <h4>Mehr erfahren auf der Proceed-Akademie</h4>
                    <motion.div whileHover={{scale: 1.02}} onClick={() => sliderHandler()} css={css`max-width: 36em;`}><Link to="/akademie"><ButtonAkademie /></Link></motion.div >
                    <h4>Oder schreiben Sie uns direkt</h4>
                    <motion.div whileHover={{scale: 1.02}} onClick={() => sliderHandler()} css={css`max-width: 36em;`}><Link to="/kontakt"><ButtonKontakt  /></Link></motion.div >
                </div>
                }
                { sliderContentState == "produktion" && 
                <div css={beratungsModal}>
                    {data && <div dangerouslySetInnerHTML={{__html: data.beratungs[0].beratungBlock4Slide.html}} />}
                    <h4>Mehr erfahren auf der Proceed-Akademie</h4>
                    <motion.div whileHover={{scale: 1.02}} onClick={() => sliderHandler()} css={css`max-width: 36em;`}><Link to="/akademie"><ButtonAkademie /></Link></motion.div >
                    <h4>Oder schreiben Sie uns direkt</h4>
                    <motion.div whileHover={{scale: 1.02}} onClick={() => sliderHandler()} css={css`max-width: 36em;`}><Link to="/kontakt"><ButtonKontakt  /></Link></motion.div >
                </div>
                }
            </motion.div>
        </motion.div>
        </SlideContainer>
        }
        </AnimatePresence> */}
        
    </>
    )
    }

}